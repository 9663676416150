import React from 'react'
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

export default () => {
    return (
        <div>
           <span >
           <Card>
               <CardContent>
               <Typography gutterBottom variant="h6" component="h6">
                 User Settings
                 </Typography>
               </CardContent>
               </Card>
            </span>

        </div>
    )
}
